import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import AirportIcon from '../Icons/AirportIcon'
import { Card, CardContent } from '../UI/Card'
import Element from '../UI/Element'
import { Col, Container, Row } from '../UI/Grid'
import { Section } from '../UI/Section'
import { Heading, Paragraph } from '../UI/Typography'

const Airport = () => {
  return (
    <Section>
      <Container>
        <Row alignItems="center" rowGap={8} justifyContent="center">
          <Col col={{ lg: 7 }}>
            <Card bg="lilac">
              <CardContent>
                <Row gutterSize={2} mb={6}>
                  <Col col="auto">
                    <Element size="h3" color="brand" lineHeight={1}>
                      <AirportIcon />
                    </Element>
                  </Col>
                  <Col col="fill">
                    <Heading size="h2">Airport and Seaport Accessible</Heading>
                  </Col>
                </Row>
                <Heading size="h6" letterSpacing="0.205em" mb={4}>
                  Prime Location for Exporting Products
                </Heading>

                <Paragraph>
                  The corridor extends from Magallanes, Makati, passes through Laguna, and reaches
                  all the way to Batangas City, is connected by the South Luzon Expressway (SLEX)
                  and the Southern Tagalog Arterial Road Tollway (STAR Tollway). Fastech is located{' '}
                  <b>65.7 km</b> from the <b>Port of Batangas</b> and <b>39.2 km</b> from the{' '}
                  <b>Ninoy Aquino International Airport (NAIA)</b>. The company is also served by a
                  global logistics provider. This gives the company easy access to both air and sea
                  transport that are used for exporting its products.
                </Paragraph>
              </CardContent>
            </Card>
          </Col>
          <Col col={{ md: 6, lg: 5 }} order={{ lg: -1 }}>
            <Element textAlign="center">
              <StaticImage src="../../images/airport-port.png" alt="map" layout="constrained" />
            </Element>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Airport
