import React from 'react'
import Icon from '../UI/Icon'

const EngineeringIcon = () => {
  return (
    <Icon viewBox="0 0 52 52">
      <path d="M19.4993 32.5C13.7143 32.5 2.16602 35.4033 2.16602 41.1667V43.3333C2.16602 44.525 3.14102 45.5 4.33268 45.5H34.666C35.8577 45.5 36.8327 44.525 36.8327 43.3333V41.1667C36.8327 35.4033 25.2843 32.5 19.4993 32.5Z" />
      <path d="M10.2687 19.5013H28.7504C29.3354 19.5013 29.812 19.0246 29.812 18.4396V18.3963C29.812 17.8113 29.3354 17.3346 28.7504 17.3346H28.1654C28.1654 14.128 26.4104 11.3763 23.832 9.85964V11.918C23.832 12.5246 23.3554 13.0013 22.7487 13.0013C22.142 13.0013 21.6654 12.5246 21.6654 11.918V8.9713C20.972 8.79797 20.257 8.66797 19.4987 8.66797C18.7404 8.66797 18.0254 8.79797 17.332 8.9713V11.918C17.332 12.5246 16.8554 13.0013 16.2487 13.0013C15.642 13.0013 15.1654 12.5246 15.1654 11.918V9.85964C12.587 11.3763 10.832 14.128 10.832 17.3346H10.2687C9.6837 17.3346 9.20703 17.8113 9.20703 18.3963V18.4613C9.20703 19.0246 9.6837 19.5013 10.2687 19.5013Z" />
      <path d="M19.5001 28.168C23.5301 28.168 26.8884 25.3946 27.8634 21.668H11.1367C12.1117 25.3946 15.4701 28.168 19.5001 28.168Z" />
      <path d="M47.623 13.4983L49.638 11.7L48.013 8.88333L45.4347 9.72833C45.1313 9.49 44.7847 9.295 44.4163 9.14333L43.8747 6.5H40.6247L40.083 9.14333C39.7147 9.295 39.368 9.49 39.043 9.72833L36.4863 8.88333L34.8613 11.7L36.8763 13.4983C36.833 13.8667 36.833 14.2567 36.8763 14.625L34.8613 16.4667L36.4863 19.2833L39.0863 18.46C39.368 18.6767 39.693 18.85 40.018 19.0017L40.6247 21.6667H43.8747L44.4597 19.0233C44.8063 18.8717 45.1097 18.6983 45.413 18.4817L47.9913 19.305L49.6163 16.4883L47.6013 14.6467C47.6663 14.235 47.6447 13.8667 47.623 13.4983ZM42.2497 16.7917C40.7547 16.7917 39.5413 15.5783 39.5413 14.0833C39.5413 12.5883 40.7547 11.375 42.2497 11.375C43.7447 11.375 44.958 12.5883 44.958 14.0833C44.958 15.5783 43.7447 16.7917 42.2497 16.7917Z" />
      <path d="M42.0321 23.3796L40.1904 23.9863C39.9737 23.813 39.7354 23.683 39.4754 23.5746L39.0854 21.668H36.7671L36.3771 23.553C36.1171 23.6613 35.8571 23.813 35.6404 23.9646L33.8204 23.358L32.6504 25.373L34.0804 26.6513C34.0587 26.933 34.0587 27.193 34.0804 27.453L32.6504 28.7746L33.8204 30.7896L35.6837 30.2046C35.9004 30.3563 36.1171 30.4863 36.3554 30.5946L36.7454 32.5013H39.0637L39.4754 30.6163C39.7137 30.508 39.9521 30.378 40.1687 30.2263L42.0104 30.8113L43.1804 28.7963L41.7504 27.4746C41.7721 27.193 41.7721 26.933 41.7504 26.673L43.1804 25.3946L42.0321 23.3796ZM37.9154 29.013C36.8537 29.013 35.9871 28.1463 35.9871 27.0846C35.9871 26.023 36.8537 25.1563 37.9154 25.1563C38.9771 25.1563 39.8437 26.023 39.8437 27.0846C39.8437 28.1463 38.9771 29.013 37.9154 29.013Z" />
    </Icon>
  )
}

export default EngineeringIcon
