import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Card, CardContent } from '../UI/Card'
import Element from '../UI/Element'
import { Col, Container, Row } from '../UI/Grid'
import { Section } from '../UI/Section'
import { Heading, Paragraph } from '../UI/Typography'
import EngineeringIcon from '../Icons/EngineeringIcon'

const Thriving = () => {
  return (
    <Section bg="lilac">
      <Container>
        <Row flexWrap={{ lg: 'nowrap' }} rowGap={6}>
          <Col col={{ lg: 5 }}>
            <Card>
              <CardContent p={{ lg: 10 }}>
                <Row gutterSize={2} mb={6}>
                  <Col col="auto">
                    <Element size="h2" color="brand" lineHeight={1}>
                      <EngineeringIcon />
                    </Element>
                  </Col>
                  <Col col="fill">
                    <Heading size="h2">Thriving Electronics Industry in the Country</Heading>
                  </Col>
                </Row>
                <Paragraph mb={3}>
                  <b>Fastech Synergy Philippines Inc is located in Laguna, Philippines</b> which is
                  found in the heart of the Manila – Laguna – Batangas (MLB) Research and Innovation
                  Consortium and Corridor.
                </Paragraph>

                <Paragraph mb={3}>
                  The <b>MLB Research and Innovation Consortium and Corridor</b> is situated along
                  the same route as the Department of Science and Technology (DOST) and the six
                  Higher Education Institutions recognized as Centers of Excellence and Centers of
                  Development in Science, Technology, Engineering, and Mathematical fields. With a
                  high concentration of both industrial and academic activity, the MLB Research and
                  Innovation Corridor bears much similarity to other regions in the world like
                  Silicon Valley and the North Carolina Research Triangle.
                </Paragraph>

                <Paragraph>
                  The <b>region</b> is a <b>hub for the electronics manufacturing industry</b>,
                  along which hundreds of electronics companies are located, and{' '}
                  <b>contributes approximately 50% of the total Philippine exports annually</b>.
                </Paragraph>
              </CardContent>
            </Card>
          </Col>
          <Col col={{ lg: 'auto' }} textAlign="center">
            <StaticImage
              src="../../images/fastech-thriving.png"
              alt="map"
              height={782}
              width={656}
              layout="constrained"
            />
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Thriving
