import React from 'react'
import Element from '../UI/Element'
import { Container, Row, Col } from '../UI/Grid'
import { Section } from '../UI/Section'
import SectionHeader from '../Shared/SectionHeader'
import { Paragraph } from '../UI/Typography'
import ShadowedImage from '../Shared/ShadowedImage'
import { StaticImage } from 'gatsby-plugin-image'
import { Card } from '../UI/Card'
import DefaultVector from '../Shared/DefaultVector'

const ManufacturingComplex = () => {
  return (
    <Section py={{ _: 16, lg: 0 }}>
      <DefaultVector>
        <Container>
          <Row rowGap={6} mb={{ lg: 20 }}>
            <Col col={{ lg: 5 }}>
              <Element as={SectionHeader} title="Manufacturing Complex" mb={4} />
              <Paragraph mb={4}>
                Fastech Synergy Philippines Inc. maintains state-of-the-art manufacturing facilities
                in the Philippines for its manufacturing operations. It is positioned to meet high
                demand for semiconductor and microwave manufacturing services.
              </Paragraph>
              <Paragraph>
                Fastech’s manufacturing complex, which boasts 25,000+ square meters of total factory
                area, is composed of three buildings.
              </Paragraph>
            </Col>
            <Col col={{ md: 3, lg: 2 }}>
              <Row rowGap={4} gutter={{ _: 2, xs: 3 }}>
                <Stat>
                  <Element size="caption">state-of-the-art manufacturing facilities</Element>
                </Stat>
                <Stat>
                  <Element size="h4" lineHeight={1.2}>
                    25,000+
                  </Element>
                  <Element size="caption">square meters</Element>
                </Stat>
                <Stat>
                  <Element as="span" size="h4" lineHeight={1.2}>
                    3
                  </Element>
                  <Element as="span" size="caption">
                    &nbsp;buildings
                  </Element>
                </Stat>
              </Row>
            </Col>
            <Col col={{ md: 9, lg: 5 }}>
              <ShadowedImage>
                <StaticImage
                  src="../../images/fastech-facade.jpg"
                  alt="fastech building"
                  layout="fullWidth"
                />
              </ShadowedImage>
            </Col>
          </Row>
        </Container>
      </DefaultVector>
    </Section>
  )
}

const Stat = ({ children }) => {
  return (
    <Col col={{ xs: 4, md: 12 }}>
      <Card
        cardhidden="true"
        bg="lilac"
        color="brand"
        flat
        textAlign="center"
        fontWeight="semibold"
        minHeight="75px"
        height="100%"
        justifyContent="center"
      >
        <Element py={2} px={3} alignItems="center">
          {children}
        </Element>
      </Card>
    </Col>
  )
}

export default ManufacturingComplex
