import React from 'react'
import Icon from '../UI/Icon'

const AirportIcon = () => {
  return (
    <Icon viewBox="0 0 39 39">
      <path d="M25.2557 4.59102L14.4163 0.749753C13.5058 0.420502 12.5086 0.420502 11.5981 0.727803L2.9483 3.66912C1.19233 4.28372 0 5.95193 0 7.83964V33.8505C0 36.9455 3.0567 39.0527 5.89661 37.9332L12.2485 35.4309C12.7254 35.2334 13.2674 35.2334 13.7443 35.409L24.5837 39.2502C25.4942 39.5795 26.4914 39.5795 27.4019 39.2722L36.0517 36.3309C37.8077 35.7382 39 34.0481 39 32.1604V6.14948C39 3.05452 35.9433 0.947304 33.1034 2.06676L26.7515 4.56907C26.2746 4.74467 25.7543 4.76662 25.2557 4.59102V4.59102ZM26.0145 35.1236L13.0072 30.4922V4.87637L26.0145 9.50785V35.1236Z" />
    </Icon>
  )
}

export default AirportIcon
