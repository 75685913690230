import React from 'react'
import { Container } from '../UI/Grid'
import { Section } from '../UI/Section'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import theme from '../../utils/theme'
import ScaledImage from '../UI/ScaledImage'
import { Card } from '../UI/Card'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import Element from '../UI/Element'

const Building = () => {
  return (
    <Section overlay="true">
      <ScaledImage bg={true}>
        <StaticImage src="../../images/bg-building-section.jpg" alt="bg" layout="fullWidth" />
      </ScaledImage>

      <Container>
        <Swiper
          effect={'coverflow'}
          initialSlide={1}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={2.2}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 500,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={{ el: '.swiper-dots', clickable: true }}
          modules={[EffectCoverflow, Pagination]}
        >
          <SwiperSlide>
            <BuildingImage>
              <Card cardhidden="true">
                <StaticImage
                  src="../../images/building-1.jpg"
                  alt="fastech building"
                  layout="fullWidth"
                />
              </Card>
              <BuildingName>
                <Element size="h4" color="white" fontWeight="bold">
                  Bldg. 1
                </Element>
              </BuildingName>
            </BuildingImage>
          </SwiperSlide>
          <SwiperSlide>
            <BuildingImage>
              <Card cardhidden="true">
                <StaticImage
                  src="../../images/building-2.jpg"
                  alt="fastech building"
                  layout="fullWidth"
                />
              </Card>
              <BuildingName>
                <Element size="h4" color="white" fontWeight="bold">
                  Bldg. 2
                </Element>
              </BuildingName>
            </BuildingImage>
          </SwiperSlide>
          <SwiperSlide>
            <BuildingImage>
              <Card cardhidden="true">
                <StaticImage
                  src="../../images/building-3.jpg"
                  alt="fastech building"
                  layout="fullWidth"
                />
              </Card>
              <BuildingName>
                <Element size="h4" color="white" fontWeight="bold">
                  Bldg. 3
                </Element>
              </BuildingName>
            </BuildingImage>
          </SwiperSlide>
          <div className="swiper-dots dots-csr dots-reverse"></div>
        </Swiper>
      </Container>
    </Section>
  )
}

const BuildingImage = styled.div`
  transition: ${theme.transition};
  /* transition-delay: 0.5s; */

  .slick-current & {
    transform: scale(1.4);
    transform-origin: center;
  }
`

const BuildingName = styled.div`
  transition: ${theme.transition};
  opacity: 0;
  text-align: center;
  color: var(--white);
  margin: 24px 0;
  /* transition-delay: 0.5s; */

  .swiper-slide-active & {
    opacity: 1;
  }
`

export default Building
