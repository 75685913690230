import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Card, CardContent } from '../UI/Card'
import Element from '../UI/Element'
import { Col, Container, Row } from '../UI/Grid'
import ScaledImage from '../UI/ScaledImage'
import { Section } from '../UI/Section'
import { Heading, Paragraph } from '../UI/Typography'
import EcozoneIcon from '../Icons/EcozoneIcon'

const Ecozone = () => {
  return (
    <Section>
      <ScaledImage bg={true}>
        <StaticImage src="../../images/bg-ecozone.jpg" alt="bg" layout="fullWidth" />
      </ScaledImage>

      <Container>
        <Row>
          <Col lg={6}>
            <Card>
              <CardContent p={{ lg: 10 }}>
                <Row alignItems="center" gutterSize={2} mb={6}>
                  <Col col="auto">
                    <Element size="h2" color="brand" lineHeight={1}>
                      <EcozoneIcon />
                    </Element>
                  </Col>
                  <Col col="fill">
                    <Heading size="h2">Certified Ecozone Export Enterprise</Heading>
                  </Col>
                </Row>
                <Element style={{ float: 'left' }} mr={4}>
                  <StaticImage src="../../images/peza.png" alt="peza" height={113} width={115} />
                </Element>

                <Paragraph mb={3}>
                  Fastech is certified by the{' '}
                  <b>Philippine Economic Zone Authority (PEZA) as an Ecozone Export Enterprise</b>.
                  As a PEZA certified Ecozone Export Enterprise, Fastech is able to streamline the
                  import and export process and is thus quick and efficient when it comes to moving
                  and shipping goods into and out of the country. Aside from this, Fastech also
                  enjoys other tax incentives.
                </Paragraph>

                <Paragraph>
                  Fastech is found inside a <b>Special Economic Zone Industrial Development</b>,
                  which is the Light Industry and Science Park I, developed by Science Park of the
                  Philippines (SPPI). As with similarly certified developments, the park has a
                  world-class infrastructure that provides high-quality and highly reliable
                  utilities such as: power, water, and telecommunications. In turn, companies
                  located inside a Special Economic Zone are highly resilient and are able to
                  operate with little to no disruptions
                </Paragraph>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Ecozone
