import React from 'react'
import Site from '../components/Layout/Site'
import FacilitiesBanner from '../components/Facilities/FacilitiesBanner'
import ManufacturingComplex from '../components/Facilities/ManufacturingComplex'
import Building from '../components/Facilities/Building'
import Seo from '../components/Layout/Seo'
import Thriving from '../components/Facilities/Thriving'
import Airport from '../components/Facilities/Airport'
import Ecozone from '../components/Facilities/Ecozone'

const Facilities = () => {
  return (
    <Site>
      <Seo title="Facilities" />
      <FacilitiesBanner />
      <ManufacturingComplex />
      <Building />
      <Thriving />
      <Airport />
      <Ecozone />
    </Site>
  )
}

export default Facilities
