import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Banner from '../Shared/Banner'
import ScaledImage from '../UI/ScaledImage'

const FacilitiesBanner = () => {
  return (
    <Banner name="Facilities" description="State-Of-the-Art<br/>Manufacturing Facilities">
      <ScaledImage bg={true}>
        <StaticImage src="../../images/banner-facilities.jpg" alt="banner" layout="fullWidth" />
      </ScaledImage>
    </Banner>
  )
}

export default FacilitiesBanner
