import React from 'react'
import Icon from '../UI/Icon'

const EcozoneIcon = () => {
  return (
    <Icon viewBox="0 0 52 52">
      <path d="M28.1673 34.9265C35.469 34.0598 41.189 28.0148 41.514 20.5398C41.8823 12.1548 34.9707 4.83147 26.5857 4.70147C18.0923 4.59313 11.2023 11.4181 11.2023 19.8681C11.2023 27.3865 16.6623 33.6048 23.834 34.7965V43.3331H13.0007C11.809 43.3331 10.834 44.3081 10.834 45.4998C10.834 46.6915 11.809 47.6665 13.0007 47.6665H39.0007C40.1923 47.6665 41.1673 46.6915 41.1673 45.4998C41.1673 44.3081 40.1923 43.3331 39.0007 43.3331H28.1673V34.9265Z" />
    </Icon>
  )
}

export default EcozoneIcon
