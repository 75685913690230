import styled from "styled-components";

const ShadowedImage = styled.div`
  position: relative;
  & > div {
    border-radius: 10px;
    overflow: hidden;
    will-change: transform;
  }

  &:before {
    content: "";
    background: #e8eaf6;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: -10px;
    right: ${(p) => (p.position === "left" ? "auto" : "-10px")};
    left: ${(p) => (p.position === "left" ? "-10px" : "auto")};
    border-radius: 10px;
  }
`;

export default ShadowedImage;
